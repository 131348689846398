import { useRouter } from "next/router";
import { type FC } from "react";

export type RedirectProps = {
	to: string;
	replace?: boolean;
};

export const Redirect: FC<RedirectProps> = ({ to, replace }) => {
	const router = useRouter();

	if (replace) {
		void router.replace(to);
	} else {
		void router.push(to);
	}

	return null;
};
